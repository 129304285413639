import { Box, Center, Flex, Spacer, Stack } from '@chakra-ui/react';
import { Footer, Navbar } from 'components/shared';
import { Helmet } from 'react-helmet';
import helmetData from 'data/helmet.json';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';

const readmeURL = 'https://raw.githubusercontent.com/loko-ai/loko/master/README.md';
export default function Docs() {
  useEffect(() => {
    fetch(readmeURL)
      .then((resp) => resp.text())
      .then((text) => setText(text));
  }, []);
  const [text, setText] = useState('');
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{helmetData.title} - Description</title>
        <meta
          name="description"
          content="Discover LOKO AI, read the documentation and download the Community Edition"
        />
        <meta property="og:type" content="website" />
        <meta name="og:url" property="og:url" content="https://loko-ai.com" />
        <meta name="og:title" property="og:title" content={helmetData.title} />
        <meta name="og:description" property="og:description" content={helmetData.description} />
        <meta name="og:image" property="og:image" content={`/images/${helmetData.image}`} />
      </Helmet>
      <Navbar />
      {/* <Box my="5rem"  h="100vh" bg="white" color="red" w="1200px">
        <iframe width="2000px" height="100%" src="https://loko-ai.github.io/"></iframe>
      </Box> */}
      <Flex p="2rem" bg="white">
        <Box px="20rem" py="1rem">
          <MarkdownPreview source={text} />
        </Box>
      </Flex>
      <Spacer />
      <Footer />
    </>
  );
}
